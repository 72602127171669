import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout.tsx"
import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"

const PrinftProject = ({ data, location }) => {
  return (
    <Layout location={location} title="Prinft.cloud Project">
      <div className="prinftcloud-project-hero" />
      <div className="lg:w-1/2 mx-16 lg:ml-12 -mt-20 ">
        <div className="absolute top-80 right-36 hidden xl:block">
          <FeedbackCard location={location} />
        </div>
        <h1>Pay-to-Print to Improve 3D Quality</h1>
        <p className="lead">
          Printf.cloud aims to be the best market place for 3D printable
          products and parts. Any print you make of a printf.cloud design pays
          the designer directly for their work. This allows designers to become
          professionals and earn a living from their talent.{" "}
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>How Does It Work?</h3>
        <p>
          A designer submits a 3D model file to the printf.cloud system. From
          here the designer can specify how they want to sell their work. They
          put the model up for sale on the site. As people buy the models,
          behind the scenes they are getting an NFT that represents their rights
          to print the 3d model they just purchased. From software on their 3d
          printer and slicer they can select a model they have rights to and
          print a copy. Depending on the license details in the NFT, the printer
          approves their ability to print the part.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>Why Would You Use It?</h3>
        <p>
          The market for 3D models continues to expand as more personal 3D
          printers are sold. The promise of the 3D printer is infinite
          reproduceability of any imaginable part (as long as it fits within the
          constrains of the printer and is made of some type available 3D
          printable material; wood, plastic, metal, resin, etc)
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>Conclusion</h3>
        <p>
          This product will only work if designers decide they want to be paid
          for their work, their work is of high enough quality that people will
          pay for, and that those people are running open source 3D printer
          software where we can plug-in to prevent un-authorized prints, and
          they’re willing to install that software to begin with.
        </p>
        <p className="mt-4">
          If we cut out the printer software side it might be easier but then it
          is more of just a marketplace and that already exists. The
          decentralized NFT side of this was that the printer software checked
          the wallet of the person authorizing the prints (but this doesn’t work
          if I want someone else to print the part for me). The NFT might have
          some lose concept of ownership but the printed item has utility that
          far outweighs the “crypto caché” of being “the only owner of X of Y 3d
          part.” NFTs and their loose grip on the digital asset they represent
          really hold value (if they hold any at all) among pure digital
          communities.
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 lg:ml-auto">
          <FeedbackCard location="projects/prinftcloud" />
        </div>
        <div className="mt-8 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default PrinftProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
